<template>
  <div v-if="starAuthors.length > 0" class="star-authors-slider-box">
    <SubHeadline tag="h2" class="star-authors-slider-box__headline">
      Entdecken Sie die Star-Autoren der Furche
    </SubHeadline>
    <div class="star-authors-slider-box__slider">
      <SliderButton :class="`slider-button-prev-${id} slider-button-prev`">
        <IconArrow fill="#109AAA" />
      </SliderButton>
      <SliderButton :class="`slider-button-next-${id} slider-button-next`">
        <IconArrow fill="#109AAA" />
      </SliderButton>
      <div v-swiper:starAuthorsSlider="swiperOptions">
        <div class="swiper-wrapper">
          <div v-for="(starAuthor, index) in starAuthors" :key="index" class="swiper-slide">
            <StarAuthorTeaser :star-author="starAuthor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import StarAuthorTeaser from '@/components/ui/teaser/star-author'
import SliderButton from '@/components/ui/button/slide'
import IconArrow from '@/components/ui/icons/icon-arrow.svg'

export default {
  components: {
    SubHeadline,
    StarAuthorTeaser,
    SliderButton,
    IconArrow,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    starAuthors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: `.slider-button-next-${this.id}`,
          prevEl: `.slider-button-prev-${this.id}`,
        },
        slidesPerView: 1.2,
        spaceBetween: 20,
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: true,
        breakpoints: {
          767: {
            slidesPerView: 3,
          },
          1023: {
            slidesPerView: 4,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.star-authors-slider-box {
  width: 100%;

  &__headline {
    margin-top: 0;
    width: 100%;
    line-height: 24px;
    font-size: 20px;

    @media all and (min-width: $screen-width-767) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__slider {
    position: relative;

    .swiper-slide {
      height: auto;
    }

    .slider-button-prev,
    .slider-button-next {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
      display: none;

      @media all and (min-width: $screen-width-767) {
        display: flex;
      }

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .slider-button-prev {
      left: 5px;

      svg {
        transform: rotate(180deg);
      }

      @media all and (min-width: $screen-width-767) {
        left: 10px;
      }

      @media all and (min-width: $screen-width-1200) {
        left: -22px;
      }
    }

    .slider-button-next {
      right: 5px;

      @media all and (min-width: $screen-width-767) {
        right: 10px;
      }

      @media all and (min-width: $screen-width-1200) {
        right: -22px;
      }
    }
  }
}
</style>
